/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import ProductsPage from "views/ProductsPage.js";
import IndexCopy from "views/Indexcopy.js";
import AboutPage from "views/about";
import RegisterPageMsForm from "views/RegisterPageMsForm";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
    <Route 
        path="/example"
        render={(props) => <IndexCopy {...props} />}
      />
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route
        path="/products-page"
        render={(props) => <ProductsPage {...props} />}
      />
       <Route
        path="/about-page"
        render={(props) => <AboutPage {...props} />}
      />
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
   
   <Route
        path="/register-page"
        render={(props) => <RegisterPageMsForm {...props} />}
      />
         <Route
        path="/icons-page"
        render={(props) => <NucleoIcons {...props} />}
      />
      
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
