/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import ProductCarousel from "components/ProductCarousel.js";
import MainHeader from "components/Headers/MainHeader.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import LandingPageHeader from "components/Headers/LandingPageHeader";


// index sections
import ProductsHeader from "components/Headers/ProductsHeader.js";

function ProductsPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <IndexNavbar />
      <ProductCarousel/>
      <div className="main">
      <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">
                  Let's talk product</h2>
                <h5 className="description">
                  <b>Koko</b>, our mascot koala, looks at many aspects when he chooses a eucalyptus tree. It's the source of life. How can you take it lightly?
                 At Koeuca, we are also fussy when it comes to choosing products. Our products are free from harmful chemicals and are sustainably sourced. After all, it's about mutual relationships. We want to prolong relationships with our customers and the environment we live in together. You can rest assured that you are getting the best quality for a reasonable price.                </h5>
                <br />
                <Button
                  className="btn-round"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  See Details
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>

              <Col md="4">
                <div className="info">
                  <div className="icon icon-info product">
                    <img
                      alt="..."
                      className="img-no-padding img-responsive img-product"
                      src={require("assets/img/products/Jarrah260g_whiteBgEdited2.png")}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Organic Jarrah Honey 260g</h4>
                    <p className="description">
                      Who doesn't like a spoonful of sweet
  tasting honey that is also low in GI? This
  Jarrah honey is packed with goodness
  with antimicrobial attributes and
  probiotics. This honey with healing
  properties is from the pristine Jarrah
  forests of Western Australia. You will
  enjoy the rich and smooth flavour with
  a hint of minerally notes.
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info product">
                    <img
                      alt="..."
                      className="img-no-padding img-responsive img-product"
                      src={require("assets/img/products/Jarrah500g_WhiteBg.png")}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Organic Jarrah Honey 500g</h4>
                    <p className="description">
                      Organic Jarrah Honey also comes in a bigger package for large families. The antimicrobial attributes and probiotics attributes of this honey keeps the doctor away. The healing property of this honey is used by many to maintain good health and cure illness. Try starting your monring with a spoonful honey!
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info product">
                    <img
                      alt="..."
                      className="img-no-padding img-responsive img-product"
                      src={require("assets/img/products/honey_products.png")}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title">(Coming soon) Organic Tasmanian Honey</h4>
                    <p className="description">
                      Special honey with unique perfume like character which leaves your mouth with a long lasting floral finish.
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
      <Footer/>

              </Col>
            </Row>
            </Container>
    </>
  );
}

export default ProductsPage;
