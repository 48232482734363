/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function MainHeader() {
  const [navbarColor, setNavbarColor] = React.useState("hide");
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("hide");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="page-header page-header-about"
        style={{
          backgroundImage: 
            "url(" + require("assets/img/g1205.png") + ")",
        }}
      >
       
        <div className="content-center ml-auto mr-auto">
          <Container> 
          <h3 className="promote">It's All About Relationships!</h3>
          <p class="promote"><b>Koeuca</b> represents the symbiotic relationship of <b>Koala</b> and <b>Eucalyptus</b> tree.<br/>
           </p>
                 </Container>
        </div>
     
        <h6 className="category category-absolute">
          
            {/* <img
              alt="..."
              className="creative-tim-logo"
              src={require("assets/img/koeuca_logo.png")}
            /> */}
        </h6>
      </div>
    </>
  );
}

export default MainHeader;
