/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import {   NavItem,
  NavLink,Row, Container } from "reactstrap";

function Footer() {
  return (
    <footer className="footer footer-black">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
            
              <li>
                <a
                  href="http://www.medium.com"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
            
           
              <li>
              <a
                data-placement="bottom"
                href="https://www.facebook.com/koeuca"
                target="_blank"
                title="Like us on Facebook"
              >
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </a>
         
              </li>
              <li>
              <a
                data-placement="bottom"
                href="https://www.instagram.com/ko.euca/"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </a>
              </li>
            </ul>
          </nav>
       
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
