/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Form, Input, Container, Row, Col, Section } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

function RegisterPageMsForm() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <IndexNavbar />
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/login-image.jpg") + ")",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" lg="9">
            <div className="register-form-koeuca register-page surface">
            <iframe width="100%" height="800px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=P37gn5G_IESk2mmSjZXIpdDHBW95lEdDr5p1r_Yi1bhUME5TQ0tFUTVNOElJUlQxRkxKODAzWEhKWC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
            </div>
            </Col>
          </Row>
        </Container>
      </div>

        <div className=" ">
       
        </div>
      
        <Footer />
      
    </>
  );
}

export default RegisterPageMsForm;
